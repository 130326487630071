import axios from "axios";

// add session token to all requests
const requestInterceptor = (config) => ({
    ...config,
    headers: {
        ...config.headers,
        'Authorization': `Bearer ` + localStorage.getItem('access_token'),
    },
});

class APIClient {
    constructor() {
        const options = {};
        var baseURL = "https://www.firstclassdata.com:4000/api";
        if (window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1')) {
            baseURL = "http://127.0.0.1:4040/api";
        }
        this.instance = axios.create({
            baseURL: baseURL,
            responseType: "json"
        });

        this.instance.interceptors.request.use(requestInterceptor);
    }

    get(url, params) {
        return this.instance.get(url, params);
    }

    post(url, params) {
        return this.instance.post(url, params).then(response => {
            return response;
        })
    }

    getLists() {
        return this.get('/lists/');
    }

    getList(rowID) {
        return this.get('/lists/' + rowID + '/');
    }

    saveList(data) {
        return this.post('/lists/', data);
    }

    search(data) {
        return this.post('/v1/list-search/', data);
    }

    login(username, password) {
        return this.post('/v1/token/', {
                username: username,
                password: password
            })
            .then(response => {
                let responseData = {
                    token: response.data.access,
                    refresh: response.data.refresh,
                    id: response.data.id,
                    username: response.data.username,
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    role: 'Admin',
                }
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);
                localStorage.setItem('user', JSON.stringify(responseData));
                return responseData;
            })
            .catch(function(error) {
                console.log(error);
                return false;
            });
    }

    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
    }
}

export default APIClient;
